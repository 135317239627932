import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Aside from './Aside'

import '../css/animate.css'
import '../css/icomoon.css'
import '../css/style.css'
import '../css/custom.css'

const BaseLayout = ({ children, isPreview }: {
  children: any
  isPreview?: boolean
}) => {
  const [showLoader, setShowLoader] = useState(true)
  isPreview = isPreview || false

  useEffect(() => {
    setShowLoader(false)
  }, [])

  return (
    <React.Fragment>
      <div id="colorlib-page">
        <Aside
          isPreview={isPreview}></Aside>
        { children }
      </div>
      <div id="ftco-loader" className={showLoader ? 'show fullscreen' : 'fullscreen'}><svg className="circular" width="48px" height="48px"><circle className="path-bg" cx="24" cy="24" r="22" fill="none" strokeWidth="4" stroke="#eeeeee"/><circle className="path" cx="24" cy="24" r="22" fill="none" strokeWidth="4" strokeMiterlimit="10" stroke="#F96D00"/></svg></div>
      <Helmet defer={false}>
        <link rel="preconnect dns-prefetch" href="https://fonts.googleapis.com" crossOrigin="anonymous"/>
        <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap" crossOrigin="anonymous"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap" crossOrigin="anonymous"/>

        <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap" crossOrigin="anonymous"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap" crossOrigin="anonymous"/>

        <link rel="preload" as="style" href="https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap" crossOrigin="anonymous"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap" crossOrigin="anonymous"/>

        {/* <link rel="preconnect dns-prefetch" href="https://stackpath.bootstrapcdn.com" crossOrigin="anonymous"/>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossOrigin="anonymous"/> */}
      </Helmet>
    </React.Fragment>
  )
}

export default BaseLayout
