import React, { useState } from 'react'
import { navigate } from '@reach/router'

const SearchWidget = () => {
  const [q, setQ] = useState(() => {
    return typeof location !== 'undefined' ? new URL(location.href).searchParams.get('q') || '' : ''
  })
  const placeHolder = 'Type and Enter to Search'
  return (
    <form className="search-form" onSubmit={(evt) => {
      evt.preventDefault()
      if (q) {
        navigate(`/?q=${encodeURIComponent(q)}`)
      } else {
        navigate(`/`)
      }
    }}>
      <div className="form-group">
        <span className="icon icon-search"></span>
        <label id="lbl-search-form" className="d-none">Search</label>
        <input placeholder={placeHolder} name="search" aria-labelledby="lbl-search-form" className="form-control" type="text" value={q} onChange={(evt) => setQ(evt.target.value)} />
      </div>
    </form>
  )
}
export default SearchWidget
