import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

const TagWidget = () => {
  const postWithTag: any = useStaticQuery(graphql`{
    allMarkdownRemark {
      nodes {
        frontmatter {
          tag
        }
      }
    }
  }`)
  let tags: string[] =  postWithTag.allMarkdownRemark.nodes.reduce((previousPost: any, currentPost: any) => {
    const tagPerPost = currentPost && currentPost.frontmatter && currentPost.frontmatter.tag ? currentPost.frontmatter.tag : []
    return previousPost.concat(tagPerPost)
  }, [])
  tags = Array.from(new Set(tags))

  return (
    <React.Fragment>
      <h3 className="sidebar-heading">Tags</h3>
      <ul className="tagcloud">
        {tags.map((tag: string) => {
          return (
            <li key={tag}>
              <Link className="tag-cloud-link" to={`/tag/${tag.toLowerCase()}`}>{tag}</Link>
            </li>
          )
        })}
      </ul>
    </React.Fragment>
  )
}
export default TagWidget
