import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, title, image }: {
  description?: any
  lang?: any
  meta?: any
  title?: any
  image?: any
}) {
  const { site } = useStaticQuery(
    graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        logo
                        lang
                        siteUrl
                    }
                }
            }
        `,
  )

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = site.siteMetadata.siteUrl
  let ogImage = image || site.siteMetadata.logo
  ogImage = `${ogImage.startsWith('/') ? siteUrl : ''}${ogImage}`
  title = title || site.siteMetadata.title
  lang = lang || site.siteMetadata.lang || 'en'

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        title === site.siteMetadata.title
          ? `${title} – ${site.siteMetadata.description}`
          : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: 'title',
          content: title,
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: siteUrl,
        },
        {
          name: 'og:image',
          content: ogImage,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          name: 'twitter:image',
          content: ogImage,
        },
      ].concat(meta)}
    >
      <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
