/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import useWindowSize from '../util/UseWindowSize'

const Aside = ({isPreview}: {
  isPreview?: boolean
}) => {
  isPreview = isPreview || false
  const path = () => typeof window === 'object' ? window.location.pathname : null
  const activeLinkClass = 'colorlib-active'
  const getCurrentYear = () => new Date().getFullYear()

  const size = isPreview ? {
    width: 0,
    height: 0,
  } : useWindowSize()
  const my1 = css`
  height: ${size.height}px`

  const [homeClass, setHomeClass] = useState('')
  const [aboutClass, setAboutClass] = useState('')
  useEffect(() => {
    setHomeClass((path() === '/' || path() === '') ? activeLinkClass : '')
    setAboutClass((path() || '').startsWith('/about') ? activeLinkClass : '')
  }, [])

  const toggle = (evt: any) => {
    evt.preventDefault()
    if (document.body.classList.contains('offcanvas')) {
      evt.currentTarget.classList.remove('active')
      document.body.classList.remove('offcanvas')
    } else {
      evt.currentTarget.classList.add('active')
      document.body.classList.add('offcanvas')
    }
  }

  return (
    <React.Fragment>
      <a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle" onClick={toggle}><i>colorlib-nav-toggle</i></a>
      <aside id="colorlib-aside" role="complementary" css={my1}>
        <nav id="colorlib-main-menu" role="navigation">
          <ul>
            <li className={homeClass}><Link to={ `/` }>Home</Link></li>
            <li className={aboutClass}><Link to={ `/about` }>About</Link></li>
          </ul>
        </nav>

        <div className="colorlib-footer">
          <p className="pfooter">
            Copyright&copy;{getCurrentYear()} All rights reserved | Theme by <a href="https://colorlib.com" rel="noopener noreferrer" target="_blank">Colorlib</a></p>
        </div>
      </aside>
    </React.Fragment>
  )
}
export default Aside
